import React, { useState } from "react";
import "./mob.css";
import { GiHamburgerMenu } from "react-icons/gi";
import {MdOutlineCancel} from "react-icons/md"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link'

function MobileNav() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [click, setClick] = useState(false);

  const closeMenu = () => setClick(false);
const  handleClick = () => {
    setOpen(!open);
  }


 
  
  return (
    <div className="block-mob " >
      <div className="mobile-version">
        <div className="mobimage">
          <Link to ="/home">
            <img src="../images/websoft-mob.svg" alt="website design"></img>
          </Link>
        </div>
        <div className= {open? "mob-nav" : "nav-links"} onClick={closeMenu}>
          <ul className= "nav-links-mobile " 
          onClick= {()=> setOpen(false)}>
            <li>
            <HashLink to="/home/#services" onClick={closeMenu}>
                {t("service")}
              </HashLink>
            </li>
            <li>
            <Link to="/portfolio"> {t("portfolio")}</Link>
            </li>
            <li>
            <HashLink to="/home/#about" onClick={closeMenu}>
              {t("about")}
              </HashLink>
            </li>
            <li>
              <HashLink href="/home/#feedback" onClick={closeMenu}>
              {t("contact")}
              </HashLink>
            </li>
          </ul>
        </div>

        <div className="hamb">
          <button 
          onClick={() => setOpen(!open)}  className="hambicon">
          {open? <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 11.25L0.75 0.75M11.25 0.75L0.75 11.25" stroke="#1E4078" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> : <GiHamburgerMenu/>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
