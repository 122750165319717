import React from "react";
import {useTranslation} from "react-i18next"

function About() {
  
  const { t } = useTranslation();

  return (
    <div className="about" id="about">
          <h3 className="abt-title"> {t("about")}</h3>

      <div className="abt_pictxt">
        <div className="Ceo">
          <img src="/images/about.webp" alt="Developer" ></img>
        </div>
        {/* <h3>{t("tnoren")}</h3>
        <p>{t("anazg")}</p> */}
       
        <div className="abt_pictxt">
          {/* <a href="" target="_blank">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z"></path>
            </svg>
          </a> */}
          <a href="">

          </a>
        </div>
      </div>

      <div className="abt_txts">
        <div>
          <h3> {t("about")}</h3>
          <div className="abt">
            <p className="abt-txt-p">
             {t('p')}
            </p>
          </div>
          <div className="abt_sup">
            <p className="abt-sup-p">{t("support")}</p>
            <ul>
            
                <p className="our-company">{t("li1")}</p>
              <div className="abt-sup-cont">
                <img
                  className="abt-sup-ket"
                  src="/images/aboutket.svg"
                  alt="websoft website development"
                ></img>
                <li>{t("li2")}</li>
              </div>
              <div className="abt-sup-cont">
                <img
                  className="abt-sup-ket"
                  src="/images/aboutket.svg"
                  alt="development"
                ></img>
                <li>{t("li3")}</li>
              </div>
              <div className="abt-sup-cont">
                <img
                  className="abt-sup-ket"
                  src="/images/aboutket.svg"
                  alt="developer"
                ></img>
                <li>{t("li4")}</li>
              </div>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
