const data = {
  cardData: [


    {
      id: 1,
      img: "./images/abio.webp",
      title1_hy: " «ԱԲԻՈ» ",
      title1_en:  "«ABIO»",
      title1_ru:  "«АБИО»",
      
      title2_hy: "Այգեգործական Հիպերմարկետ",
      tech_en: "Horticultural Hypermarket",
      tech_ru: "Садоводческий гипермаркет",

      desc_hy: "Աբիոն հանդիսանում է Հայաստանում գործող առաջատար այգեգործական հիպերմարկետներից մեկը:  ",
      desc_en:  "Abio is one of the leading horticultural hypermarkets operating in Armenia.",
      desc_ru:  "Abio один из ведущих садоводческих гипермаркетов, работающих в Армении.",
      


      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 4 անդամ",
      team_en: "Tim. 4 members",
      team_ru: "Тим. 4 члена",
      
      time_hy: "Տևողություն։ 2 ամիս", 
      time_en: "Duration. 2 months",
      time_ru: "Продолжительность. 2 месяца",


      java: "./images/java.svg",
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
      link: 'https://abio.am/'
    },

    {
      id: 2,
      img: "./images/sievnn.webp",
      title1_hy: "«SiEVN» ",
      title1_en:  "«SiEVN»",
      title1_ru:  "«SiEVN» ",

      title2_hy: "Անշարժ Գույք",
      tech_en: "Real Estate",
      tech_ru: "Недвижимость",


      desc_hy: "Մենք ներկայացնում ենք SiEvn-ը ''Մնա Երևանում'' ընկերությունը, որը 5 տարի է ինչ զբաղվում է բնակարանրերի, տների, կոմերցիոն տարածքների, վաճառքով և վարձակալությամբ։",
      desc_en:  "We represent SiEvn, the ''Stay in Yerevan'' company, which has been engaged in the sale and rent of apartments, houses, commercial premises for 5 years.",
      desc_ru:  "Представляем SiEvn, компанию ''Оставайтесь в Ереване'', которая уже 5 лет занимается продажей и арендой квартир, домов, коммерческих помещений.",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 3 անդամ",
      team_en: "Tim. 3 members",
      team_ru: "Тим. 3 члена",
      
      time_hy: "Տևողություն։ 1.5 ամիս", 
      time_en: "Duration. 1.5 months",
      time_ru: "Продолжительность. 2 месяца",


      java: "./images/java.svg",
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
   
      link: 'https://sievn.am/'
    },

    {
      id: 3,
      img: "./images/haymayrer.webp",
      title1_hy: "«Հայ Մայրեր» ",
      title1_en:  "«Armenian Mothers»",
      title1_ru:  "«Армянские  Mатери»",

      title2_hy: "Բարեգործական ՀԿ",
      tech_en: "The charitable public organization",
      tech_ru: "О благотворительной общественной организации",


      desc_hy: "Կազմակերպությունն Աջակցում Է Մանկական Ուղեղային Կաթված Ախտորոշում Ունեցող Երեխաներին Ու Նրանց Ընտանիքներին",
      desc_en:  "Charitable Public Organization supports children diagnosed with Cerebral Palsy and their families.",
      desc_ru:  "Общественная  благитворительная о рганизация  Армянские Матери  предоставляет помощь детям с Церебральным Параличом  и их семьям",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 3 անդամ",
      team_en: "Tim. 3 members",
      team_ru: "Тим. 3 члена",
      
      time_hy: "Տևողություն։ 1.5 ամիս", 
      time_en: "Duration. 1.5 months",
      time_ru: "Продолжительность. 2 месяца",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://haymayrer.am/'
    },


    {
      id: 3,
      img: "./images/imper.webp",
      title1_hy: "«imperfoss»",
      title1_en:  "«imperfoss»",
      title1_ru:  "«imperfoss» ",

      title2_hy: "Շինարարական",
      tech_en: "Construction",
      tech_ru: "Строительство",


      desc_hy: "Դուք վստահում եք, մենք կառուցում ենք! Ընկերությունն ունենալով մեծ փորձ կառուցապատման ոլորտում պատրաստ է օգնել և կազմակերպել շինթուլտվության վկայականի ձեռք բերման հարցում։ ",
      desc_en:  "You trust, we build! The company, having a lot of experience in the field of construction, is ready to help and organize the acquisition of a building permit.",
      desc_ru:  "Вы доверяете, мы строим! Компания, имеющая большой опыт в сфере строительства, готова помочь и организовать получение разрешения на строительство.",


      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 1.5 շաբաթ", 
      time_en: "Duration. 1.5 weeks",
      time_ru: "Продолжительность. 1.5 недели",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://imperfoss.am/'
    },


    {
      id: 4,
      img: "./images/cosmod.webp",
      title1_hy: "Cosmod ",
      title1_en:  "Cosmod",
      title1_ru:  "Cosmod",

      title2_hy: "Վաճառքի Օնլայն Հարթակ",
      tech_en: "Online Sales Platform",
      tech_ru: "Платформа онлайн-продаж",


      desc_hy: "Կոսմետիկաների Օնլայն Հարթակ",
      desc_en:  "Online Cosmetics Platform",
      desc_ru:  "Косметическая онлайн-платформа",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 1.5 ամիս", 
      time_en: "Duration. 1.5 months",
      time_ru: "Продолжительность. 1.5 месяца",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://cosmod.be/'
    },


    {
      id: 5,
      img: "./images/kzhc.am.webp",
      title1_hy: "«Քանաքեռ-Զեյթուն»  ",
      title1_en:  "«Kanaker-Zeytun»",
      title1_ru:  "«Канакер-Зейтун»",

      title2_hy: "Առողջության Կենտրոն ՓԲԸ",
      tech_en: "Health Center CJSC",
      tech_ru: "ЗАО «Центр здоровья»",


      desc_hy: "Քանաքեռ - Զեյթուն Թիվ 16 պոլիկլինիկան",
      desc_en:  "Kanaker - Zeytun Polyclinic No. 16",
      desc_ru:  "Канакер-Зейтунская поликлиника №16",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 2 շաբաթ", 
      time_en: "Duration. 2 weeks",
      time_ru: "Продолжительность. 2 недели",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://kzhc.am/'
    },


    {
      id: 7,
      img: "./images/coverbox.webp",
      title1_hy: "  «ARSCUSTOM»  ",
      title1_en:  "«ARSCUSTOM» ",
      title1_ru:  "«ARSCUSTOM» ",

      title2_hy: "Մեքենաներ",
      tech_en: "Cars",
      tech_ru: "Автомобили",

      desc_hy: "«ARSCUSTOM» հուսալի և բարձրորակ պատուհանների ներկման ծառայություններ Շվեյցարիայում:",
      desc_en:  "«ARSCUSTOM» reliable and high quality window tinting services in Switzerland. ",
      desc_ru:  "«ARSCUSTOM» надежные и качественные услуги по тонированию окон в Швейцарии.",


      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 3 անդամ",
      team_en: "Tim. 3 members",
      team_ru: "Тим. 3 члена",
      
      time_hy: "Տևողություն։ 2 ամիս", 
      time_en: "Duration.  2 months",
      time_ru: "Продолжительность. 2 месяца",

      figma: "./images/figma.svg",
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",

      link: 'https://arscustom.ch/'
    },





    {
      id: 8,
      img: "./images/avagranite.webp",
      title1_hy: "«AVA Granit» ",
      title1_en:  "«AVA Granit»",
      title1_ru:  "«AVA Granit»",

      title2_hy: "AVA Granit ՓԲԸ Շինարարական",
      tech_en: "AVA Granit CJSC Construction",
      tech_ru: "АВА Гранит ЗАО Строительство",


      desc_hy: " AVA Granit ՓԲԸ մասնագիտացված է շինարարական, կառուցապատման և հանքարդյունաբերության ոլորտներում:",
      desc_en:  "AVA Granit CJSC specializes in the construction, development and mining industries.",
      desc_ru:  "АВА Гранит ЗАО специализируется в сферах строительства, девелопмента и горнодобывающей промышленности.",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 1 անդամ",
      team_en: "Tim. 1 members",
      team_ru: "Тим. 1 члена",
      
      time_hy: "Տևողություն։ 1.5 շաբաթ", 
      time_en: "Duration. 1.5 weeks",
      time_ru: "Продолжительность. 1.5 недели",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://avagranite.am/'
    },



    {
      id: 9,
      img: "./images/nornorq.webp",
      title1_hy: "«Նոր-Նորք» ",
      title1_en:  "«Nor-Nork»",
      title1_ru:  "«Нор-Норк»",

      title2_hy: "Առողջության Կենտրոն ՓԲԸ",
      tech_en: "Health Center CJSC",
      tech_ru: "ЗАО «Центр здоровья»",


      desc_hy: "«Թիվ 22 պոլիկլինիկա» ՓԲԸ",
      desc_en:  "Polyclinic No. 22 CJSC",
      desc_ru:  "Поликлиника № 22 ЗАО ",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 2.5 շաբաթ", 
      time_en: "Duration. 2.5 weeks",
      time_ru: "Продолжительность. 2.5 недели",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://nornorq.am/'
    },



    {
      id: 10,
      img: "./images/ervada.webp",
      title1_hy: "Երվադա",
      title1_en:  "«Ervada»",
      title1_ru:  "«Ervada»",

      title2_hy: "Արտադրամաս",
      tech_en: "Workshop",
      tech_ru: "Фабрика",


      desc_hy: "Ընկերությունը զբաղվում է շինարարությամբ, ներմուծմամբ և արտադրությամբ։",
      desc_en:  "The company is engaged in construction, import and production.",
      desc_ru:  "Компания занимается строительством, импортом и производством.",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 1 անդամ",
      team_en: "Tim. 1 members",
      team_ru: "Тим. 1 члена",
      
      time_hy: "Տևողություն։ 5 օր", 
      time_en: "Duration. 5 day",
      time_ru: "Продолжительность. 5 день",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://ervada.am/'
    },


    {
      id: 11,
      img: "./images/tesaranshin.webp",
      title1_hy: "ՏԵՍԱՐԱՆՇԻՆ ",
      title1_en:  "«TESARANSHIN»",
      title1_ru:  "TESARANSHIN",

      title2_hy: "Կառուցապատող Կազմակերպություն",
      tech_en: "Construction Company",
      tech_ru: "Развивающаяся организация",


      desc_hy: "ՏԵՍԱՐԱՆՇԻՆ ՍՊԸ Կառուցապատող Կազմակերպություն",
      desc_en:  "TESARANSHIN Construction Company",
      desc_ru:  "TESARANSHIN Развивающаяся организация",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 1 անդամ",
      team_en: "Tim. 1 members",
      team_ru: "Тим. 1 члена",
      
      time_hy: "Տևողություն։ 5 օր", 
      time_en: "Duration. 5 day",
      time_ru: "Продолжительность. 5 день",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://tesaranshin.am/'
    },


    {
      id: 12,
      img: "./images/karmirblur.webp",
      title1_hy: "«Կարմիր Բլուր» ",
      title1_en:  "«Karmir Blur»",
      title1_ru:  "«Karmir Blur»",

      title2_hy: "Առողջության Կենտրոն ՓԲԸ",
      tech_en: "Health Center CJSC",
      tech_ru: "ЗАО «Центр здоровья»",

      desc_hy: "«Կարմիր Բլուր» Առողջության Կենտրոն ՓԲԸ ",
      desc_en:  "«Karmir Blur» Health Center CJSC",
      desc_ru:  "«Karmir Blur» ЗАО «Центр здоровья»",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 2.5 շաբաթ", 
      time_en: "Duration. 2.5 weeks",
      time_ru: "Продолжительность. 2.5 недели",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://karmirblur.am/'
    },

    {
      id: 13,
      img: "./images/amazone.am.webp",
      title1_hy: "«AMAZONE» ",
      title1_en:  "«AMAZONE»",
      title1_ru:  "«AMAZONE»",

      title2_hy: "Վաճառքի Օնլայն Հարթակ",
      tech_en: "Online Sales Platform",
      tech_ru: "Платформа онлайн-продаж",


      desc_hy: "Ընտրելու համար Ձեր ճանապարհորդությունը դեպի անխափան առցանց գնումների օնլայն հարթակ սկսվում է այստեղ amazone.am",
      desc_en:  "Your journey to a seamless online shopping platform starts here amazone.am",
      desc_ru:  "Ваш путь к удобной платформе онлайн-покупок начинается здесь amazone.am",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 3 անդամ",
      team_en: "Tim. 3 members",
      team_ru: "Тим. 3 члена",
      
      time_hy: "Տևողություն։ 1.5 ամիս", 
      time_en: "Duration. 1.5 months",
      time_ru: "Продолжительность. 2 месяца",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://amazone.am/'
    },


    
    {
      id: 14,
      img: "./images/eventlilit.webp",
      title1_hy: "«Event's» ",
      title1_en:  "«Event's»",
      title1_ru:  "«Event's»",

      title2_hy: "Event's by Lilit",
      tech_en: "Event's by Lilit",
      tech_ru: "Event's by Lilit",


      desc_hy: "Ծաղկազարդ, ամուսնության առաջարկ, փուչիկներով զարդարում, վարձույթ",
      desc_en:  "Flower decorations, marriage proposal, balloon decoration, rental",
      desc_ru:  "Цветочные украшения, предложение руки и сердца, оформление воздушными шарами, аренда",

 
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",


      team_hy: "Թիմ։ 1 անդամ",
      team_en: "Tim. 1 members",
      team_ru: "Тим. 1 члена",
      
      time_hy: "Տևողություն։ 5 օր", 
      time_en: "Duration. 5 day",
      time_ru: "Продолжительность. 5 день",


    
      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
     
      link: 'https://eventlilit.com/'
    },

    {
      id: 15,
      img: "./images/ontimeautoam.webp",
      title1_hy:  "  «ՕՆԹԱՅՄ ԱՎՏՈ»  ",
      title1_en:  "«ONTIME AUTO»",
      title1_ru:  "«ОНТАЙМ АВТО»",

      title2_hy: "Մեքենաների Ներմուծում",
      tech_en: "Import of cars",
      tech_ru: "Импорт автомобилей",

      desc_hy: " ՕՆԹԱՅՄ ԱՎՏՈ-ն առաջատար ընկերությունների պաշտոնական ներկայացուցիչ է, որը մասնագիտացված է միջազգային փոխադրումների մեջ։",
      desc_en:  "ONTIME AUTO is an official representative of leading companies specializing in international transportation.",
      desc_ru:  "ОНТАЙМ АВТО официальный представитель ведущих компаний, специализирующихся на международных перевозках.",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 1.5 շաբաթ", 
      time_en: "Duration. 1.5 weeks",
      time_ru: "Продолжительность. 1.5 недели",

      figma: "./images/figma.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
    
     
      link: 'https://ontimeauto.org/'
    },

    {
      id: 16,
      img: "./images/hayemyes.webp",
      title1_hy: " «ՀԱՅԵՄԵՍ» ",
      title1_en:  "«HayemYes»",
      title1_ru:  "«HayemYes»",

      title2_hy: "Բարեգործական Հիմնադրամ",
      title2_en:  "Charitable Foundation",
      title2_ru:  "Благотворительный фонд",

      desc_hy: "Հանրային, սոցալ-տնտեսական նախաձեռնություն է,ուղղված Հայկական պետականության, հայ մարդու բարեկեցիկ, կայուն և ապահով ապագային։",
      desc_en:  "It is a public, socio-economic initiative aimed at the prosperous, stable and safe future of the Armenian statehood and the Armenian people.",
      desc_ru:  "Это общественная, социально-экономическая инициатива, направленная на благополучное, стабильное и безопасное...",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 4 շաբաթ", 
      time_en: "Duration. 4 weeks",
      time_ru: "Продолжительность. 4 недели",
      
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      figma: "./images/figma.svg",
      link: 'https://hayemyes.am/'
      
    },
 
    {
      id: 17,
      img: "./images/ecoroll.webp",
      title1_hy: "  «ԷԿՈՌՈԼԼ»  ",
      title1_en:  " «EcoRoll» ",
      title1_ru:  " «ЭКОРОЛЛ»",

      title2_hy: "Պլաստիկ Տոպրակներ",
      tech_en: "Plastic bags",
      tech_ru: "Пластиковые пакеты",

      desc_hy: " «ԷԿՈՌՈԼԼ» ՍՊԸ-ն, իր բնագավառում լինելով առաջատարներից մեկը, հագեցած է ժամանակակից տեխնոլոգիաներով: ",
      desc_en:  " «ECOROLL» LLC, being one of the leaders in its field, is equipped with modern technologies.",
      desc_ru:  " «ЭКОРОЛЛ» ООО, являясь одним из лидеров в своей области, оснащено современными технологиями.",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 1.5 շաբաթ", 
      time_en: "Duration. 1.5 weeks",
      time_ru: "Продолжительность. 1.5 недели",

      figma: "./images/figma.svg",
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",

      link: 'https://ecoroll.am/'
    },


    // {
    //   id: 9,
    //   img: "./images/citytoys.am.jpg",
    //   title1_hy: "  «CITYTOYS»  ",
    //   title1_en:  " «CITYTOYS» ",
    //   title1_ru:  " «CITYTOYS»",

    //   title2_hy:"Ինտերնետ Խանութ",
    //   tech_en: "Internet Shop",
    //   tech_ru: "Интернет магазин",

    //   desc_hy: "CITYTOYS.AM-ը առցանց (online) խանութ է , որում կարող եք գտնել նվերների և խաղալիքների լայն տեսականի Ձեր երեխաների համար:  ",
    //   desc_en:  "CITYTOYS.AM is an online store where you can find a wide range of gifts and toys for your children. ",
    //   desc_ru:  "CITYTOYS.AM – интернет-магазин, где вы можете найти широкий выбор подарков и игрушек для ваших детей. ",

    //   tech_hy: "Օգտագործված տեխնոլոգիաներ",
    //   tech_en: "Technologies used",
    //   tech_ru: "Используемые технологии",

    //   team_hy: "Թիմ։ 2 անդամ",
    //   team_en: "Tim. 2 members",
    //   team_ru: "Тим. 2 члена",
      
    //   time_hy: "Տևողություն։ 1.5 շաբաթ", 
    //   time_en: "Duration. 1.5 weeks",
    //   time_ru: "Продолжительность. 1.5 недели",


      
    //   html: "./images/htmllogo.svg",
    //   css: "./images/csslogo.svg",
    //   link: 'https://citytoys.am/'
    // },


    {
      id: 18,
      img: "./images/harisa.am.png",
      title1_hy: "  «MAMA's FOOD»  ",
      title1_en:  " «MAMA's FOOD» ",
      title1_ru:  " «MAMA's FOOD»",

      title2_hy:"Ինտերնետ Խանութ",
      tech_en: "Internet Shop",
      tech_ru: "Интернет магазин",

      desc_hy: "MAMA's FOOD ընկերությունը սպառողին առաջարկում է հայկական համեղ և օգտակար կերակրատեսակներ.  ",
      desc_en:  "MAMA's FOOD company offers consumers delicious and healthy Armenian dishes. ",
      desc_ru:  "MAMA's FOOD предлагает потребителям вкусные и полезные блюда армянской кухни. ",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 7 օր", 
      time_en: "Duration. 7 days",
      time_ru: "Продолжительность. 7 дней",


      
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      link: 'https://harisa.am/'
    },

    // {
    //   id: 11,
    //   img: "./images/strongarmenia.org.jpg",
    //   title1_hy: " «strongarmenia» ",
    //   title1_en:  "«strongarmenia»",
    //   title1_ru:  "«strongarmenia»",

    //   title2_hy: "Բարեգործական Հիմնադրամ",
    //   title2_en:  "Charitable Foundation",
    //   title2_ru:  "Благотворительный фонд",

    //   desc_hy: "Հնարավորության դեպքում ավելի բարի եղեք. իսկ դա միշտ հնարավոր է։",
    //   desc_en:  "Be kinder whenever possible: and this is always possible.",
    //   desc_ru:  "Будьте добрее, когда это возможно. А это возможно всегда.",

    //   tech_hy: "Օգտագործված տեխնոլոգիաներ",
    //   tech_en: "Technologies used",
    //   tech_ru: "Используемые технологии",

    //   team_hy: "Թիմ։ 2 անդամ",
    //   team_en: "Tim. 2 members",
    //   team_ru: "Тим. 2 члена",
      
    //   time_hy: "Տևողություն։ 4 շաբաթ", 
    //   time_en: "Duration. 4 weeks",
    //   time_ru: "Продолжительность. 4 недели",
      
     
    //   html: "./images/htmllogo.svg",
    //   css: "./images/csslogo.svg",
    //   figma: "./images/figma.svg",
    //   link: 'https://strongarmenia.org/am'
      
    // },

    {
      id: 19,
      img: "./images/ghazinyan.webp",
      title1_hy:  "  «Ղազինյան և Գործընկերներ»  ",
      title1_en:  "«Ghazinyan & Partners»",
      title1_ru:  "«Казинян и Партнеры»",

      title2_hy: "Իրավաբանական",
      tech_en: "law firm",
      tech_ru: "юридической консалтинговой",

      desc_hy: " «Ղազինյան և գործընկերներ» թիմը կազմված է փորձառու և գիտական հարուստ կենսափորձ ունեցող, ոլորտի առաջատար մասնագետներից:",
      desc_en:  "«Ghazinyan & Partners» team includes leading professionals with rich scientific life experience, who are guided by the principle of finding quick",
      desc_ru:  "«Казинян и Партнеры» команду входят ведущие специалисты с богатым научным жизненным опытом",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 3 անդամ",
      team_en: "Tim. 3 members",
      team_ru: "Тим. 3 члена",
      
      time_hy: "Տևողություն։ 30 օր", 
      time_en: "Duration. 30 days",
      time_ru: "Продолжительность. 30 дней",

      figma: "./images/figma.svg",
      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",

      link: 'https://ghazinyanpartners.com/'
    },

    {
      id: 20,
      img: "./images/httpsnjsparta.webp",
      title1_hy: "  «Sparta Solutions»  ",
      title1_en:  "«Sparta Solutions» ",
      title1_ru:  "«Sparta Solutions» ",

      title2_hy: "Ծրագրային Ապահովում",
      tech_en: "Software",
      tech_ru: "Программное обеспечение",

      desc_hy:  "Sparta Solutions ԱՄՆ-ում, ՏՏ մասնագետների նվիրված և փորձառու թիմե:",
      desc_en:  "Sparta Solutions USA, a dedicated and experienced team of IT professionals.",
      desc_ru:  "Sparta Solutions, Преданная и опытная команда ИТ-специалистов в США",

      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 1 անդամ",
      team_en: "Tim. 1 members",
      team_ru: "Тим. 1 члена",
      
      time_hy: "Տևողություն։ 10 օր", 
      time_en: "Duration. 10 days",
      time_ru: "Продолжительность. 10 дней",

      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      link: 'https://njsparta.com/'
    },

    {
      id: 21,
      img: "./images/succulentshouse.png",
      title1_hy: "  «Succulents House»  ",
      title1_en:  " «Succulents» ",
      title1_ru:  " «Succulents» ",

      title2_hy: "Օնլայն Խանութ",
      tech_en: "Online Shop",
      tech_ru: "Интернет магазин",

      desc_hy: " Succulents House-ը,իր գործունեությունը`մասնավորապես սուկուլենտների վաճառքը սկսել է 2020 թվականից:  ",
      desc_en:  "Succulents House started its activities, especially the sale of succulents, in 2020.",
      desc_ru:  "Succulents House начал свою деятельность, в частности продажу суккулентов, в 2020 году.",

      
      tech_hy: "Օգտագործված տեխնոլոգիաներ",
      tech_en: "Technologies used",
      tech_ru: "Используемые технологии",

      team_hy: "Թիմ։ 2 անդամ",
      team_en: "Tim. 2 members",
      team_ru: "Тим. 2 члена",
      
      time_hy: "Տևողություն։ 2 շաբաթ", 
      time_en: "Duration. 2 weeks",
      time_ru: "Продолжительность. 2 недели",

      react: "./images/reactlogo.svg",
      html: "./images/htmllogo.svg",
      css: "./images/csslogo.svg",
      link: 'https://succulentshouse.am/'
    },
  ],
};
export default data;
