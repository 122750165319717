import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import left from "../../img/portfolioleft.svg"
import right  from "../../img/portfolioright.svg"

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./slid.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import PortfolioSlid from "./PortfolioSlid";
import PortfolioSlid2 from "./PortfolioSlid-2";
import PortfolioSlid3 from "./PortfolioSlid-3";
import PortfolioSlid4 from "./PortfolioSlid-4";
import PortfolioSlid5 from "./PortfolioSlid-5";
import PortfolioSlid6 from "./PortfolioSlid-6";
import PortfolioSlid7 from "./PortfolioSlid-7";
import PortfolioSlid8 from "./PortfolioSlid-8";


export const Slid = () => {
  const [slideBegOrNot, handleSlideByState] = useState({
    isFirst: true,
    isLast: false,
  });
  const SlideRef = useRef();

  const handleNext = () => {
    SlideRef.current.swiper.slideNext();
  };

  const handlePrev = () => {
    SlideRef.current.swiper.slidePrev();
  };

  const onSlideChange = (swiper) => {
    handleSlideByState({
      isFirst: swiper.isBeginning,
      isLast: swiper.isEnd,
    });
  };

  const { isLast, isFirst } = slideBegOrNot;

  return (
    <>
      <div className="spasi">
        <div className="bs-icons">
          <img src={left}
            size="20px" color="red"
            className={`Arrow ${isFirst ? "disabled" : ""}`}
            onClick={handlePrev}
          />
          <img src={right}
            size="20px" 
            className={`Arrow ${isLast ? "disabled" : ""}`}
            onClick={handleNext}
          />
        </div>
      </div>
      <Swiper
        ref={SlideRef}
        slidesPerView={3}
        spaceBetween={20}
        onSlideChange={onSlideChange}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          300: {
            slidesPerView: 2,
            centeredSlides: false,
          },

          650: {
            centeredSlides: false,
            slidesPerView: 2,
          },

          1001: {
            slidesPerView: 3,
          },
        }}
        grabCursor={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper-3"
      >
        <SwiperSlide>
          <PortfolioSlid />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid2 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid3 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid4 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid5 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid6 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid7 />
        </SwiperSlide>

        <SwiperSlide>
          <PortfolioSlid8 />
        </SwiperSlide>

      </Swiper>

    </>
  );
};
