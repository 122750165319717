import React, { useState, useRef } from "react";
import {useTranslation} from "react-i18next"
import emailjs from '@emailjs/browser';
// import * as yup from "yup";

// import { userSchema } from "../Validations/UserValidation";

function Feedback() {
  const form = useRef();
  const { t } = useTranslation();
  const [state, setState] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wa7zpyl', 'template_7c22a16', state, {
        publicKey: 'VX34OgjI1gTFm_Z7D',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  

  // const sendEmail = (event) => {
  //   event.preventDefault();
  //   const formData = {
  //     name: event.target[0].value,
  //     email: event.target[1].value,
  //     number: event.target[2].value,
  //     message: event.target[3].value,
  //   };
  //   console.log(formData);

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(state),
  //   };
  //   fetch("http://localhost:5000/send", requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => console.log(data));

  //   event.target.reset();
  // };

  return (
    <div className="feed_all" id="feedback">
      <div className="h3">
        <h3>{t("feedback")}</h3>
      </div>
      
      <div className="feedback">
      <div className="left-line"></div>
        <div className="feed_image">
          <img src="/images/feedback.svg" alt="#"></img>
        </div>
        <div className="right-line"></div>

        <form onSubmit={sendEmail} className="contact">
          <div className="contact_inputs">
            <input
              onChange={handleChange}
              name="name"
              className="input"
              type="text"
              placeholder={t("anazg1")}
            />
            <input
              onChange={handleChange}
              name="email"
              className="input"
              type="email"
              placeholder={t("mail")}
            ></input>
            <input
              onChange={handleChange}
              name="number"
              className="input"
              type="number"
              placeholder={t("number")}
            ></input>
            <input
              className="input"
              onChange={handleChange}
              name="message"
              type="text"
              placeholder={t("message")}
            ></input>
          </div>
  
          <input
            type="submit"
            name=""
            value={t("contsend")}
            className="input_btn dblockbtn"
          ></input>
           <input
            type="submit"
         
            value={t("contsend")}
            className="input_btn  dnonebtn"
          ></input>


        </form>
      </div>
    </div>
  );
}

export default Feedback;
