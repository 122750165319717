import React from "react";
import combo from "../../img/hayemyes.webp"
import himn from "../../img/Logo Himnadram.svg"
import { useTranslation } from "react-i18next"
import i18n from "i18next";

 
function PortfolioSlid() {
  const { t } = useTranslation();
  return (
    <div className="portfolio">
      <div className="port_boxes">
        <div className="portfolio_box">
          <div className="img">
            <img src={himn} className="port_logo"></img>
          </div>
          <h3>{t("port2")}</h3>
          <div className="img-comp-cont">
          <img src={combo} className="img-comp"></img>
          </div>
        </div>
 
      </div>
     
    </div>
  );
}

export default PortfolioSlid;
 